import React, { useCallback, useEffect, useState } from "react";
import LinePattern from "../../../assets/svgs/LinePattern";
import useValidate from "../../hooks/useValidate";
import "./Contact.scss";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ContactModal from "../../components/UI/ContactModal/ContactModal";
import { sendEmail } from "../../services/contact";

const Contact = () => {
  const [validation, setValidation] = useState(false);
  const [modal, setModal] = useState(false);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validateNotEmpty = (value) => {
    return value.trim().length > 3;
  };

  const {
    value: email,
    isValid: emailValid,
    hasError: emailError,
    valueBlurHandler: emailBlurHandler,
    valueChangedHandler: emailChangeHandler,
    reset: emailReset,
  } = useValidate(validateEmail);
  const {
    value: name,
    isValid: nameValid,
    hasError: nameError,
    valueBlurHandler: nameBlurHandler,
    valueChangedHandler: nameChangeHandler,
    reset: nameReset,
  } = useValidate(validateNotEmpty);
  const {
    value: surname,
    isValid: surnameValid,
    hasError: surnameError,
    valueBlurHandler: surnameBlurHandler,
    valueChangedHandler: surnameChangeHandler,
    reset: surnameReset,
  } = useValidate(validateNotEmpty);
  const {
    value: message,
    isValid: messageValid,
    hasError: messageError,
    valueBlurHandler: messageBlurHandler,
    valueChangedHandler: messageChangeHandler,
    reset: messageReset,
  } = useValidate(validateNotEmpty);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha("yourAction");
    // Do whatever you want with the token
    setValidation(true);
  }, [executeRecaptcha]);

  let formValid = false;

  if (emailValid && nameValid && surnameValid && messageValid) {
    formValid = true;
  }

  const sendMail = () => {
    if (!formValid) {
      return;
    }

    sendEmail({
      name: name,
      surname: surname,
      email: email,
      phone: document.getElementById("landing-contact-phone").value,
      message: message,
      subject: "",
    }).then((data) => {
      setModal(true);
    });
  };

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <>
      {" "}
      {modal && <ContactModal close={() => setModal(false)} />}
      <div className="contact-page-wrapper">
        {/* <ContactModal /> */}
        <LinePattern />
        <div className="contact-page-container">
          <div className="left">
            <h1 className="primary-heading">Contact Us</h1>
            <ul>
              <li>
                <h3>Email Us</h3>
                <p>contact@medex.me</p>
              </li>
              <li>
                <h3>Call Us</h3>
                <p>+382 20 513 333</p>
              </li>
              <li>
                <h3>Visit Us</h3>
                <p>Svetlane Kane Radevic 1, <br /> 81000 Podgorica Montenegro, <br /> 2nd floor</p>
              </li>
            </ul>
          </div>
          <div className="right">
            <h3>
              <span>OR</span> Simply leave your details and we will get back to
              you.
            </h3>
            <form>
              <div className="form-group double-form-group">
                <div className="form-group-item">
                  <label htmlFor="landing-contact-first-name">First name</label>
                  <input
                    type="text"
                    id="landing-contact-first-name"
                    placeholder="Jordan"
                    value={name}
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                  />
                  {nameError && (
                    <p className="error-text-active">This field is required.</p>
                  )}
                </div>
                <div className="form-group-item">
                  <label htmlFor="landing-contact-last-name">Last name</label>
                  <input
                    type="text"
                    id="landing-contact-last-name"
                    placeholder="Smith"
                    value={surname}
                    onChange={surnameChangeHandler}
                    onBlur={surnameBlurHandler}
                  />
                  {surnameError && (
                    <p className="error-text-active">This field is required.</p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-item">
                  <label htmlFor="landing-contact-email">e-mail</label>
                  <input
                    type="text"
                    id="landing-contact-email"
                    placeholder="your@email.com"
                    value={email}
                    onChange={emailChangeHandler}
                    onBlur={emailBlurHandler}
                  />
                  {emailError && (
                    <p className="error-text-active">
                      The e-mail address entered is invalid.
                    </p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-item">
                  <label htmlFor="landing-contact-phone">phone number</label>
                  <input
                    type="text"
                    id="landing-contact-phone"
                    placeholder="+0 (123) 45 67 891"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-item">
                  <label htmlFor="landing-contact-message">message</label>
                  <input
                    type="text"
                    id="landing-contact-message"
                    placeholder="Leave your message here..."
                    value={message}
                    onChange={messageChangeHandler}
                    onBlur={messageBlurHandler}
                  />
                  {messageError && (
                    <p className="error-text-active">This field is required.</p>
                  )}
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  sendMail();
                }}
              >
                CONTACT ME
              </button>
              <p className="recaptcha-text">
                This site is protected by reCAPTCHA and the Google{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://policies.google.com/privacy"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://policies.google.com/terms"
                >
                  Terms of Service
                </a>{" "}
                apply.
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
